import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { useErrorBoundary } from 'react-error-boundary';
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from '../../../../store/loadingReducer';
import { toastConfig } from '../../../utils';
import CustomTextBox from '../../CustomTextBox';
import { KUBN1 } from './musicWidgetUtils';
import api from '../../../api/CallMusicApi';
import Axios from 'axios';

interface Props {
  setSearchResult: (result: any[]) => void;
}

const MusicCdSyohinSearchBox: React.FC<Props> = ({ setSearchResult }) => {
  const [searchDiscno, setSearchDiscno] = useState<string>('');
  const [searchTitle, setSearchTitle] = useState<string>('');
  const [searchArtist, setSearchArtist] = useState<string>('');
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  const onClickSearch = async () => {
    try {
      dispatch(startLoading());
      setSearchResult([]);
      const result = await api.getSyohin(searchDiscno, searchTitle, searchArtist, KUBN1.CD);
      if (!result.length) {
        toast.error('検索条件に該当するアルバム・シングルは見つかりませんでした。', toastConfig());
        return;
      }
      toast.success(result.length < 500 ? `${result.length}件該当しました。` : '500件以上該当しました。500件までを表示しています。', toastConfig());
      setSearchResult(result);
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400) {
        toast.error(e.response.data, toastConfig());
      } else {
        showBoundary(e);
      }
    } finally {
      dispatch(stopLoading());
    }
  };

  return (
    <Row style={{ margin: '10px', marginTop: '10px' }}>
      <Col md={1}>
        <div style={{ alignItems: 'center', display: 'flex', flexFlow: 'column' }}>
          <img src={`${process.env.PUBLIC_URL}/images/musicWidget/searchDisc.png`} className="w-100 mt-1" />
          <img src={`${process.env.PUBLIC_URL}/images/musicWidget/flow.png`} className="w-50 mt-5" />
        </div>
      </Col>
      <Col md={7}>
        <Card style={{ padding: '10px', backgroundColor: '#E3F1FF' }}>
          <CustomTextBox
            label="レコード商品番号"
            value={searchDiscno}
            onChange={(e) => setSearchDiscno(e.target.value)}
            style={{ fontSize: '14px' }}
          />
          <CustomTextBox
            label="アルバム・シングル等のタイトル"
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            style={{ fontSize: '14px', marginTop: '-10px' }}
          />
          <CustomTextBox
            label="アーティスト"
            value={searchArtist}
            onChange={(e) => setSearchArtist(e.target.value)}
            style={{ fontSize: '14px', marginTop: '-10px' }}
          />
          <Row>
            <Col md={{ span: 3, offset: 9 }}>
              <Button variant="primary" type="button" as="input" value="検索" onClick={onClickSearch} style={{ width: '100%' }} />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default MusicCdSyohinSearchBox;
