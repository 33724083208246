import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { AccountStrings } from '../../interface/AccountStrings';

interface ConfirmSendBackModalProps {
  isDisplayModal: boolean;
  accountStrings: AccountStrings;
  handleCloseModal: () => void;
  handleClickOk: () => void;
  setReason: (reason: string) => void;
}

const ConfirmSendBackModal: React.FC<ConfirmSendBackModalProps> = ({
  isDisplayModal,
  accountStrings,
  handleCloseModal,
  handleClickOk,
  setReason,
}) => {
  const [confirmed, setConfirmed] = useState<Boolean>(false);
  const beforeImage = `${process.env.PUBLIC_URL}/images/confirmReportAuthTransition/confirmSendBackBefore_${accountStrings.imageFileName}.png`;
  const afterImage = `${process.env.PUBLIC_URL}/images/confirmReportAuthTransition/confirmSendBackAfter_${accountStrings.imageFileName}.png`;
  const contentsMargin = '30px';

  const clickCloseButton = () => {
    setConfirmed(false);
    handleCloseModal();
  };

  return (
    <Modal show={isDisplayModal} onHide={clickCloseButton} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>利用報告の差戻</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ justifyContent: 'center' }}>
        <div style={{ display: confirmed ? 'none' : 'block', textAlign: 'center' }}>
          <img src={beforeImage} alt="confirmSendBackBefore" width="100%" style={{ padding: contentsMargin }} />
          <div style={{ paddingBottom: contentsMargin }}>
            選択した利用報告を
            <a style={{ color: 'red' }}>
              <b>差戻</b>
            </a>
            して、
            <br />
            {accountStrings.subordinateAccount}に送信しますか？
            <br />（{accountStrings.userAccount}からは編集等が行えなくなります）
            <br />
            <div style={{ display: 'flex', textAlign: 'left', justifyContent: 'center', marginTop: '20px' }}>
              <Form.Group style={{ width: '80%' }}>
                <label htmlFor="sendBackReason">差戻理由</label>
                <Form.Control
                  type="text"
                  id="sendBackReason"
                  placeholder="差戻理由を入力いただけます"
                  onChange={(event) => setReason(event.target.value)}
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div style={{ display: !confirmed ? 'none' : 'block', textAlign: 'center' }}>
          <img src={afterImage} alt="confirmSendBackAfter" width="100%" style={{ padding: contentsMargin }} />
          <div style={{ paddingBottom: contentsMargin }}>
            {accountStrings.subordinateAccount}への送信が
            <a style={{ color: 'red' }}>完了しました。</a>
            <br />
            利用報告にご協力いただきありがとうございます。
            <br />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: confirmed ? 'none' : 'block', textAlign: 'right', marginTop: '10px' }}>
        <Button variant="outline-primary" onClick={clickCloseButton}>
          キャンセル
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            setConfirmed(true);
            handleClickOk();
          }}
        >
          差戻する
        </Button>
      </Modal.Footer>
      <Modal.Footer style={{ display: !confirmed ? 'none' : 'block', textAlign: 'right', marginTop: '30px' }}>
        <Button variant="secondary" onClick={clickCloseButton}>
          この画面を閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmSendBackModal;
