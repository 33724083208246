import { Button, Row, Col, Container } from 'react-bootstrap';
import Stepper from '../components/Stepper';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import SelectBigMediaCategory from './SelectSubBigMedia';
import { DANCE_TITLE, THEATER_TITLE } from '../utils';
import BackButton from './BackButton';
import reportApi from '../api/CallCreateReportApi';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { useDispatch } from 'react-redux';
import { getBigMediaCategoryHelpComponent } from '../helpUtils';
import {
  setBigMediaCategoryTmp,
  setSubBigMediaCategoryTmp,
  removeMediaCategoryTmpById,
  setMediaCategoryTmp,
  removeMediaTmpById,
  removeSubBigMediaCategoryTmpById,
} from '../localStorageUtils';

interface Props {
  bigMediaCategory: string;
  subBigMediaCategory: string;
  movePage: () => void;
  setBigMediaCategory: (category: string) => void;
  setSubBigMediaCategory: (category: string) => void;
  userInfo: BaseUserInfo;
  moveMediaPage: () => void;
  setDisplayHelpData: (data: any) => void;
  setHelpPaddingTop: (style: any) => void;
}

const Category: React.FC<Props> = ({
  bigMediaCategory,
  subBigMediaCategory,
  movePage,
  setBigMediaCategory,
  userInfo,
  setDisplayHelpData,
  setSubBigMediaCategory,
  moveMediaPage,
  setHelpPaddingTop,
}) => {
  const dispatch = useDispatch();

  const nextPage = () => {
    if (subBigMediaCategory) setSubBigMediaCategoryTmp(userInfo._id, subBigMediaCategory);
    setBigMediaCategoryTmp(userInfo._id, bigMediaCategory);
    removeMediaCategoryTmpById(userInfo._id);
    const getMediaCategory = async () => {
      try {
        dispatch(startLoading());
        const data = await reportApi.getMediaCategory(bigMediaCategory);
        if (data[0].children.length === 0) {
          setMediaCategoryTmp(userInfo._id, data[0].value.join(','));
          removeMediaTmpById(userInfo._id);
          if (moveMediaPage) moveMediaPage();
        } else {
          movePage();
        }
      } finally {
        dispatch(stopLoading());
      }
    };
    getMediaCategory();
  };

  const createDisplayHelpData = async (category: string, num: number) => {
    const style = { paddingTop: String(num - 150) + 'px' };
    setHelpPaddingTop(style);
    try {
      const Component = await getBigMediaCategoryHelpComponent(category);
      setDisplayHelpData(Component);
    } catch (e: any) {
      setDisplayHelpData([]);
    }
  };

  const clickMediaCategory = (category: string, event: any) => {
    createDisplayHelpData(category, event.pageY);
    setBigMediaCategory(category);
    setSubBigMediaCategory('');
    removeSubBigMediaCategoryTmpById(userInfo._id);
  };

  const clickDance = () => {
    setSubBigMediaCategory(DANCE_TITLE);
    setBigMediaCategory('');
  };

  const clickTheater = () => {
    setSubBigMediaCategory(THEATER_TITLE);
    setBigMediaCategory('');
  };

  const isActive = (value: string) => {
    return subBigMediaCategory === '' && bigMediaCategory === value;
  };

  const selectBigMediaCategoryBySub = (category: string, num: number) => {
    setBigMediaCategory(category);
    createDisplayHelpData(category, num);
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="ta-left">
              <BackButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Stepper activeNum={1} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h4>STEP2 送信した著作物の入手・掲載元を選択してください</h4>
            <hr className="border-1-solid" />
            <div>選択したら次へを押してください。</div>
            <div className="ta-right">
              <Button
                disabled={bigMediaCategory === ''}
                variant="success"
                as="input"
                type="button"
                value="次へ"
                className="m-10"
                onClick={() => {
                  nextPage();
                }}
              />
            </div>
            <div>
              <Button
                className="book-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('書籍・本', event);
                }}
                active={isActive('書籍・本')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/book.png`} alt="book_icon" className="w-20" />
                書籍・本
              </Button>
              <Button
                className="book-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('雑誌', event);
                }}
                active={isActive('雑誌')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/magazine.png`} alt="magazine_icon" className="w-20" />
                雑誌
              </Button>
              <Button
                className="paper-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('新聞', event);
                }}
                active={isActive('新聞')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/newspaper.png`} alt="newspaper_icon" className="w-20" />
                新聞
              </Button>

              <Button
                className="textbook-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('検定教科書', event);
                }}
                active={isActive('検定教科書')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/school.png`} alt="school_icon" className="w-20" />
                検定教科書
              </Button>
              <Button
                className="textbook-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('教師用指導書及びその付属教材、指導者用デジタル教科書', event);
                }}
                active={subBigMediaCategory === '' && isActive('教師用指導書及びその付属教材、指導者用デジタル教科書')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/school.png`} alt="school_icon" className="w-20" />
                教師用指導書及びその付属教材、指導者用デジタル教科書
              </Button>
              <Button
                className="textbook-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('副読本、副教材等の教材', event);
                }}
                active={isActive('副読本、副教材等の教材')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/school_fill.png`} alt="school_icon" className="w-20" />
                副読本、副教材等の教材
              </Button>

              <Button
                className="internet-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('インターネット上の著作物等全般', event);
                }}
                active={isActive('インターネット上の著作物等全般')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/internet.png`} alt="internet" className="w-20" />
                インターネット上の著作物等全般
              </Button>
              <Button
                className="music-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('音楽', event);
                }}
                active={isActive('音楽')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/music.png`} alt="music_icon" className="w-20" />
                音楽
              </Button>
              <Button
                className="music-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('映像DVD・ブルーレイ等', event);
                }}
                active={isActive('映像DVD・ブルーレイ等')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/movie.png`} alt="movie_icon" className="w-20" />
                映像DVD・ブルーレイ等
              </Button>

              <Button
                className="broadcast-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('放送（テレビ・ラジオ）', event);
                }}
                active={isActive('放送（テレビ・ラジオ）')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/tv.png`} alt="tv_icon" className="w-20" />
                放送（テレビ・ラジオ）
              </Button>
              <Button
                className="broadcast-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('有線放送（ケーブルテレビ・有線ラジオ）', event);
                }}
                active={isActive('有線放送（ケーブルテレビ・有線ラジオ）')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/connection.png`} alt="connection_icon" className="w-20" />
                有線放送（ケーブルテレビ・有線ラジオ）
              </Button>
              <Button
                className="paper-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('冊子、パンフレット等', event);
                }}
                active={isActive('冊子、パンフレット等')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/pamphlet.png`} alt="pamphlet" className="w-20" />
                冊子、パンフレット等
              </Button>

              <Button
                className="paper-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('過去の試験問題', event);
                }}
                active={isActive('過去の試験問題')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/test.png`} alt="test_icon" className="w-20" />
                過去の試験問題
              </Button>
              <div>教師や学生のダンス・演劇・演奏・歌唱等を、リアルタイム配信や録音・録画して配信した場合はこちらを選択してください。</div>
              <Button
                className="dance-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={() => {
                  clickDance();
                }}
                active={subBigMediaCategory === '(ダンス等)既存の振り付け'}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/dance.png`} alt="dance" className="w-20" />
                (ダンス等)既存の振り付け
              </Button>
              <Button
                className="dance-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={() => {
                  clickTheater();
                }}
                active={subBigMediaCategory === '(演劇等)既存の台本'}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/theater.png`} alt="theater" className="w-20" />
                (演劇等)既存の台本
              </Button>
              <Button
                className="dance-button-color highlighted-button publisher-button-size m-10"
                type="button"
                onClick={(event) => {
                  clickMediaCategory('（歌唱、演奏等）既存の楽曲', event);
                }}
                active={isActive('（歌唱、演奏等）既存の楽曲')}
              >
                <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/sing.png`} alt="sing" className="w-20" />
                （歌唱、演奏等）既存の楽曲
              </Button>
              {subBigMediaCategory && (
                <div>
                  {subBigMediaCategory === DANCE_TITLE && (
                    <div>振り付けの掲載元を選択してください。当該授業の教師や学生が創作した振り付けはご報告不要です。</div>
                  )}
                  {subBigMediaCategory === THEATER_TITLE && (
                    <div>台本の掲載元を選択してください。当該授業の教師や学生が創作した台本はご報告不要です。</div>
                  )}
                  <SelectBigMediaCategory
                    selectedItem={bigMediaCategory}
                    setSelected={selectBigMediaCategoryBySub}
                    subBigMediaCategory={subBigMediaCategory}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="ta-right">
              <Button
                disabled={bigMediaCategory === ''}
                variant="success"
                as="input"
                type="button"
                value="次へ"
                className="m-10"
                onClick={() => {
                  nextPage();
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Category;
