import axios from 'axios';
import { useErrorBoundary } from 'react-error-boundary';

const BASE_API_URL = process.env.REACT_APP_API_URL;
const BASE_CONFIG = {
  baseURL: BASE_API_URL,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const unexpectedExceptionClient = axios.create(BASE_CONFIG);

export const expectedExceptionClient = axios.create(BASE_CONFIG);

export const imageClient = axios.create({
  baseURL: BASE_API_URL,
  timeout: 30000,
  headers: { 'content-type': 'multipart/form-data' },
});

export function AxiosClientProvider({ children }: { children: React.ReactElement }) {
  const { showBoundary } = useErrorBoundary();
  unexpectedExceptionClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      showBoundary(error.response);
      return Promise.reject(error);
    }
  );

  expectedExceptionClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );
  return <>{children}</>;
}
