import { useEffect, useState } from 'react';
import { Row, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../utils';
import { KUBN1, WIDGET_TYPE } from './musicWidgetUtils';
import MusicCdSyohinSearchBox from './MusicCdSyohinSearchBox';
import MusicHaishinSyohinSearchBox from './MusicHaishinSyohinSearchBox';
import MusicSyohinSelectList from './MusicSyohinSelectList';
import musicWidgetUtils from './musicWidgetUtils';

interface ModalProps {
  display: boolean;
  setDisplay: (value: boolean) => void;
  kubn: KUBN1;
  onlySyohin?: boolean;
  updateMediaMusic: (value: any) => void;
}

const SearchMusicBySyohinModal: React.FC<ModalProps> = ({ display, setDisplay, kubn, onlySyohin = false, updateMediaMusic }) => {
  const widgetType = WIDGET_TYPE.SYOHIN;
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [selectedFirstList, setSelectedFirstList] = useState<any>({});
  const [selectedSecondList, setSelectedSecondList] = useState<any>({});
  const listColumns = musicWidgetUtils.getListColumns(widgetType, kubn);

  const onClickConfirm = () => {
    if (onlySyohin) {
      if (!Object.keys(selectedFirstList).length) {
        toast.error('アルバム・シングル等を選択してください。', toastConfig());
        return;
      }
      updateMediaMusic(selectedFirstList);
      toast.success('選択した商品情報を入力項目に反映させました。', toastConfig());
    } else {
      if (!Object.keys(selectedSecondList).length) {
        toast.error('利用楽曲を選択してください。', toastConfig());
        return;
      }
      updateMediaMusic({
        syohinno: selectedFirstList.syohinno,
        title: selectedFirstList.title,
        hatubaibi: selectedFirstList.hatubaibi,
        ...selectedSecondList,
      });
      toast.success('選択した楽曲情報を入力項目に反映させました。', toastConfig());
    }
    setDisplay(false);
    setSearchResult([]);
  };

  useEffect(() => {
    setSelectedFirstList({});
    setSelectedSecondList({});
  }, [searchResult]);

  useEffect(() => {
    setSelectedSecondList({});
  }, [selectedFirstList]);

  return (
    <Modal
      size="lg"
      show={display}
      onHide={() => {
        setDisplay(false);
        setSearchResult([]);
      }}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>アルバム・シングル等の検索</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ padding: '10px' }}>
          <div>いずれかの項目を入力して、アルバム・シングル等{!onlySyohin && 'と利用楽曲'}を検索してください。</div>
        </Row>
        <Row>
          {kubn === KUBN1.CD && <MusicCdSyohinSearchBox setSearchResult={setSearchResult} />}
          {kubn === KUBN1.HAISHIN && <MusicHaishinSyohinSearchBox setSearchResult={setSearchResult} />}
        </Row>
        {onlySyohin ? (
          <Row>
            <MusicSyohinSelectList
              listIndex={0}
              columns={listColumns[0]}
              displayItems={searchResult}
              onlySyohin={true}
              setSelectedItem={setSelectedFirstList}
            />
          </Row>
        ) : (
          <>
            <Row>
              <MusicSyohinSelectList
                listIndex={0}
                columns={listColumns[0]}
                displayItems={searchResult}
                onlySyohin={false}
                setSelectedItem={setSelectedFirstList}
              />
            </Row>
            <Row>
              <MusicSyohinSelectList
                listIndex={1}
                columns={listColumns[1]}
                displayItems={'kyoku_info' in selectedFirstList ? selectedFirstList['kyoku_info'] : []}
                onlySyohin={false}
                setSelectedItem={setSelectedSecondList}
              />
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setDisplay(false);
            setSearchResult([]);
          }}
        >
          閉じる
        </Button>
        <Button variant="success" onClick={onClickConfirm}>
          反映
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchMusicBySyohinModal;
