import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Gear, BoxArrowUpRight, DoorClosedFill } from 'react-bootstrap-icons';
import { Col, Row, Container, Button } from 'react-bootstrap';
import api from '../../api/CallOwnerApi';
import { BaseUserInfo } from '../../interface/BaseUserInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import NavigateCautionModal from './NavigateCautionModal';
import { ACCOUNT } from '../../enum/AccountEnum';

export interface HeaderProps {
  user: BaseUserInfo;
  isBtnDisabled: boolean;
  isDisableHalfWrittenBtn: boolean;
}

const Header: React.FC<HeaderProps> = ({ user, isBtnDisabled, isDisableHalfWrittenBtn }) => {
  const globalDisplayName = useSelector((state: RootState) => state.displayName.displayName);
  const globalInstitutionName = useSelector((state: RootState) => state.institution.name);
  const [isDisplayModal, setIsDisplayModal] = useState<boolean>(false);
  const [isRegistOrEditOrCopyPage, setIsRegistOrEditOrCopyPage] = useState<boolean>(false);
  const [nextStep, setNextStep] = useState<() => void>(() => () => setIsDisplayModal(false));
  const navigate = useNavigate();
  const location = useLocation();

  const cautionModalWrapper = (invokedFunction: () => void) => {
    if (isRegistOrEditOrCopyPage) {
      setNextStep(() => () => {
        invokedFunction();
        setIsDisplayModal(false);
      });
      setIsDisplayModal(true);
      return;
    }
    invokedFunction();
  };

  const handleClickReportsPageButton = () => navigate('reports');
  const moveCreateNewReportPage = () => navigate('regist/lesson');
  const moveHalfWrittenReportPage = () => {
    localStorage.setItem(user._id + '_editType', 'halfWritten');
    navigate('regist/lesson');
  };
  const handleClickInstitutionsPageButton = () => navigate('institutions');
  const handleClickInfoPageButton = () => navigate('info');
  const handleClickLogoutButton = async () => {
    await api.Logout();
    navigate('login');
  };

  useEffect(() => {
    setIsRegistOrEditOrCopyPage(
      location.pathname.includes('/copy/') || location.pathname.includes('/edit/') || location.pathname.includes('/regist/')
    );
  }, [location]);

  return (
    <>
      <Container className="base-header mt-4 mb-3">
        <Row>
          <Col md="12" lg="2" xl="3">
            <img
              src={`${process.env.PUBLIC_URL}/images/new_sartras_logo.png`}
              alt="sartras_logo"
              className="header-logo"
              onClick={() => cautionModalWrapper(handleClickReportsPageButton)}
              style={{ cursor: 'pointer' }}
            />
          </Col>
          <Col md="12" lg="10" xl="9" className="d-flex flex-column">
            <div>
              <Container>
                <Row>
                  <Col md="9" lg="12" className="displayName-position">
                    {globalInstitutionName}
                    <br />
                    {ACCOUNT.OWNER}： {globalDisplayName} 様
                  </Col>
                  <Col md="3" className="p-0 my-2 medium-settings">
                    <div className="mr-3">
                      <Gear
                        color="royalblue"
                        size={30}
                        onClick={() => cautionModalWrapper(handleClickInfoPageButton)}
                        className="d-block cursor-pointer"
                      />
                      <div style={{ fontSize: '13px' }}>設定</div>
                    </div>
                    <div>
                      <DoorClosedFill
                        color="royalblue"
                        size={30}
                        onClick={() => cautionModalWrapper(handleClickLogoutButton)}
                        className="d-block m-auto cursor-pointer"
                      />
                      <div style={{ fontSize: '13px' }}>ログアウト</div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div>
              <Container>
                <Row>
                  <Col md="12" lg="10">
                    <Button
                      variant="primary"
                      className="m-1"
                      as="input"
                      type="button"
                      value="新規登録"
                      onClick={moveCreateNewReportPage}
                      disabled={isBtnDisabled}
                    />
                    <Button
                      variant="primary"
                      className="m-1"
                      as="input"
                      type="button"
                      value="書きかけ編集"
                      onClick={moveHalfWrittenReportPage}
                      disabled={isBtnDisabled || isDisableHalfWrittenBtn}
                    />
                    <Button
                      variant="primary"
                      className="m-1"
                      as="input"
                      type="button"
                      value="利用報告一覧"
                      onClick={() => cautionModalWrapper(handleClickReportsPageButton)}
                    />
                    <Button
                      variant="primary"
                      className="m-1"
                      as="input"
                      type="button"
                      value="教育機関管理"
                      onClick={() => cautionModalWrapper(handleClickInstitutionsPageButton)}
                    />
                    <a href="https://sartras.or.jp/hokoku/hokoku-documents/" target="_blank" rel="noreferrer" className="m-1">
                      <Button variant="primary" type="button">
                        関連資料
                        <BoxArrowUpRight size={15} className="document-icon" />
                      </Button>
                    </a>
                  </Col>
                  <Col className="p-0 my-2 lager-settings" style={{ marginLeft: '-20px' }}>
                    <div className="mr-3">
                      <a href="https://sartras.or.jp/tsumugi/opinion/" target="_blank" rel="noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/images/post.png`} style={{ marginLeft: '5px', width: '30px' }} />
                      </a>
                      <div style={{ fontSize: '13px' }}>ご意見</div>
                    </div>
                    <div className="mr-1">
                      <Gear
                        color="royalblue"
                        size={30}
                        onClick={() => cautionModalWrapper(handleClickInfoPageButton)}
                        className="d-block m-auto cursor-pointer"
                      />
                      <div style={{ fontSize: '13px' }}>設定</div>
                    </div>
                    <div>
                      <DoorClosedFill
                        color="royalblue"
                        size={30}
                        onClick={() => cautionModalWrapper(handleClickLogoutButton)}
                        className="d-block m-auto cursor-pointer"
                      />
                      <div style={{ fontSize: '13px' }}>ログアウト</div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      <NavigateCautionModal isDisplayModal={isDisplayModal} handleCloseModal={() => setIsDisplayModal(false)} handleClickOk={nextStep} />
    </>
  );
};

export default Header;
