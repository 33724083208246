import { BaseUserInfo } from '../interface/BaseUserInfo';
import { FrontWork } from '../interface/FrontWork';
import { FrontWorkTmp, isFrontWorkTmpType } from '../interface/FrontWorkTmp';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Stepper from './Stepper';
import InputArea from './InputWorkArea';
import { useEffect, useState, useCallback, useTransition } from 'react';
import reportApi from '../api/CallCreateReportApi';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { useDispatch } from 'react-redux';
import {
  removeAllTmpDataById,
  removeAllHalfWrittenDataById,
  saveWorkPageTmpDataAsHalfWrittenData,
  getRequiredMediaCategoryTmpById,
  getSubBigMediaCategoryTmpById,
  setWorksTmp,
} from '../localStorageUtils';
import BackButton from './BackButton';
import WorkHelp from './WorkHelpComponent';
import React from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import Axios from 'axios';

interface Props {
  userInfo: BaseUserInfo;
  moveReportsPage: () => void;
  moveNextUrl: () => void;
  worksByUpperLayer: FrontWork[];
  isDisplayAddDeleteButton: boolean;
  isDisplayHalfWrittenButton: boolean;
}

const InputWork: React.FC<Props> = React.memo(
  ({ userInfo, moveReportsPage, moveNextUrl, worksByUpperLayer, isDisplayAddDeleteButton, isDisplayHalfWrittenButton }) => {
    const [works, setWorks] = useState<FrontWork[]>([]);
    const [smallCategories, setSmallCategories] = useState<string>('');
    const [subCategories, setSubcategories] = useState<string[]>([]);
    const dispatch = useDispatch();
    const [toggleNextButtonClicked, setToggleNextButtonClicked] = useState<boolean>(false);
    const [toggleUpdateForm, setToggleUpdateForm] = useState<boolean>(false);
    const [toggleHalfWrittenButtonClicked, setToggleHalfWrittenButtonClicked] = useState<boolean>(false);
    const [isDisableSaveBtn, setIsDisableSaveBtn] = useState<boolean>(true);
    const [isDisplayAddDelButton, setIsDisplayAddDelButton] = useState<boolean>(false);
    const [submitNum, setSubmitNum] = useState<number>(0);
    const [helps, setHelps] = useState<any[][]>([[]]);
    const [mediaCategory, setMediaCategory] = useState<string>('');
    const { showBoundary } = useErrorBoundary();
    const [isLoadingWorks, setIsLoadingWorks] = useState<boolean[]>([]);
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
      (async () => {
        if (isLoadingWorks.length === 0) return;
        if (isLoadingWorks.includes(true)) dispatch(startLoading());
        else dispatch(stopLoading());
      })();
    }, [isLoadingWorks]);

    const addWork = useCallback(() => {
      setWorks((prevInputList) => prevInputList.concat({ data: {}, disabled: true }));
      setHelps((prevHelps) => prevHelps.concat([[]]));
    }, []);

    const deleteWork = useCallback((deleteIndex: number) => {
      setWorks((prevInputList) => prevInputList.filter((item, index) => index !== deleteIndex));
      setHelps((prevHelps) => prevHelps.filter((arr, index) => index !== deleteIndex));
      setToggleUpdateForm((prev) => !prev);
    }, []);

    const setIsWorkDisabled = (disabledIndex: number, disabled: boolean) => {
      setWorks((prevInputList) => prevInputList.map((item, index) => (index === disabledIndex ? { data: item.data, disabled: disabled } : item)));
    };

    const changeFormData = useCallback((formData: any, targetIndex: string, type: string) => {
      setWorks((prevInputList) =>
        prevInputList.map((input, index) => (index === Number(targetIndex) ? { ...input, data: { ...input.data, formData: formData } } : input))
      );
      if (type === 'change') return;
      setSubmitNum((preNum) => preNum + 1);
    }, []);

    const setWorkInfo = useCallback((targetIndex: number, uiSchema: any, schema: any, subCategory: string, titleDict: object) => {
      setWorks((prevInputList) =>
        prevInputList.map((input, index) => {
          if (index !== Number(targetIndex)) return input;
          return {
            ...input,
            data: {
              ...input.data,
              uiSchema: uiSchema,
              schema: schema,
              subCategory: subCategory,
              titles: titleDict,
            },
          };
        })
      );
    }, []);

    const createLocalTmpData = () => {
      const results: FrontWorkTmp[] = [];
      works.forEach((input, index) => {
        if (!isFrontWorkTmpType(input.data)) throw Error('FrontWorkTmp不一致');
        const titles = input.data.titles;
        const subCategory = input.data.subCategory;
        const work = {
          id: index,
          formData: input.data.formData,
          disabled: input.disabled,
          subCategory: subCategory,
          titles: titles,
        };
        results.push(work);
      });
      setWorksTmp(userInfo._id, results);
    };

    const saveTmp = () => {
      // 一時保存
      removeAllHalfWrittenDataById(userInfo._id);
      saveWorkPageTmpDataAsHalfWrittenData(userInfo._id);
      removeAllTmpDataById(userInfo._id);
    };

    useEffect(() => {
      if (!worksByUpperLayer.length) return;
      (async () => {
        try {
          dispatch(startLoading());
          const categories = getRequiredMediaCategoryTmpById(userInfo._id);
          setSmallCategories(categories);
          const categoryArray = categories?.split(',');
          const subBigMediaCategoryTmp = getSubBigMediaCategoryTmpById(userInfo._id);
          const categoryTree = categoryArray.map((item, index) => (index === 0 ? item : item.slice(2, item.length))).join(' > ');
          setMediaCategory(subBigMediaCategoryTmp ? subBigMediaCategoryTmp + ' > ' + categoryTree : categoryTree);
          const subCategories = await reportApi.getSubCategory(categoryArray);
          setSubcategories(subCategories);
          // 新規作成
          setWorks(worksByUpperLayer);
          setIsLoadingWorks(() => Array(worksByUpperLayer.length).fill(false));
          const emptyArrays: any[][] = Array(worksByUpperLayer.length).fill([]);
          setHelps(emptyArrays);
          setIsDisplayAddDelButton(isDisplayAddDeleteButton);
          const schema = await reportApi.getWorkSchema(categories.split(','), subCategories[0]);
          if (subCategories.length === 1 && schema.properties.title?.title === '入力項目なし') setIsDisplayAddDelButton(false);
        } catch (e: any) {
          if (!Axios.isAxiosError(e)) showBoundary(e);
          showBoundary(e.response);
        } finally {
          dispatch(stopLoading());
        }
      })();
    }, [worksByUpperLayer]);

    useEffect(() => {
      if (works.length === 0) return;
      if (works.length === submitNum) {
        if (toggleHalfWrittenButtonClicked) {
          createLocalTmpData();
          saveTmp();
          moveReportsPage();
        } else {
          createLocalTmpData();
          moveNextUrl();
        }
      } else {
        // エラーが発生して一部のみsubmitされるパターン
        setSubmitNum(0);
      }
    }, [submitNum]);

    useEffect(() => {
      const disabled = works.some((work) => work.disabled);
      setIsDisableSaveBtn(disabled);
    }, [works]);

    useEffect(() => {
      setToggleNextButtonClicked((prev) => !prev);
    }, [toggleHalfWrittenButtonClicked]);

    return (
      <>
        <Row>
          <Col md={12}>
            <div className="ta-left">
              <BackButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Stepper activeNum={2} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h4>STEP3 送信した著作物の情報を1つずつ入力してください</h4>
            <hr className="border-1-solid" />
            <div className="text-black-50 mb-1">{mediaCategory}</div>
          </Col>
        </Row>
        {works.map((item, index) => (
          <Row className="m-10" key={index}>
            <Col md={9} lg={8}>
              <Card className="work-card">
                <Card.Body>
                  <Card.Title>{index + 1}</Card.Title>
                  <InputArea
                    smallCategoryArray={smallCategories.split(',')}
                    subCategories={subCategories}
                    toggleNextButtonClicked={toggleNextButtonClicked}
                    toggleHalfWrittenButtonClicked={toggleHalfWrittenButtonClicked}
                    toggleUpdateForm={toggleUpdateForm}
                    setFormData={changeFormData}
                    workData={item.data}
                    setIsWorkDisabled={setIsWorkDisabled}
                    setWorkInfo={setWorkInfo}
                    setDisplayHelpData={(componentArray: any, index: number) =>
                      setHelps((prevHelps) => prevHelps.map((help, ind) => (ind === index ? componentArray : help)))
                    }
                    index={index}
                    helpData={helps[index]}
                    setIsLoadingWork={(index: number, newFlag: boolean) =>
                      setIsLoadingWorks((prev) => prev.map((isLoading, idx) => (idx === index ? newFlag : isLoading)))
                    }
                  />
                </Card.Body>
                <Card.Footer>
                  {isDisplayAddDelButton && (
                    <Button variant="danger" as="input" type="button" onClick={() => deleteWork(index)} value="削除" className="m-10" />
                  )}
                </Card.Footer>
              </Card>
            </Col>
            <Col md={3} lg={4} className="pl-0 pr-0">
              {helps[index] && <WorkHelp helps={helps[index]} />}
            </Col>
          </Row>
        ))}
        <Row>
          <Col md={{ span: 3, offset: 9 }}>
            <div className="ta-right">
              {isDisplayAddDelButton && <Button variant="success" as="input" type="button" onClick={addWork} value="追加" className="m-10" />}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="ta-right">
              {isDisplayHalfWrittenButton && (
                <Button
                  variant="success"
                  as="input"
                  type="submit"
                  disabled={works.length < 1 || isDisableSaveBtn}
                  onClick={() => {
                    setIsLoadingWorks(() => Array(works.length).fill(true));
                    startTransition(() => setToggleHalfWrittenButtonClicked((prev) => !prev));
                  }}
                  value="書きかけ保存"
                  className="m-10"
                />
              )}
              <Button
                variant="success"
                as="input"
                type="submit"
                disabled={works.length < 1 || isDisableSaveBtn}
                onClick={() => {
                  setIsLoadingWorks(() => Array(works.length).fill(true));
                  startTransition(() => setToggleNextButtonClicked((prev) => !prev));
                }}
                value="次へ"
                className="m-10"
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
);

export default InputWork;
