import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AccountStrings } from '../../interface/AccountStrings';

interface ConfirmApproveModalProps {
  isDisplayModal: boolean;
  accountStrings: AccountStrings;
  handleCloseModal: () => void;
  handleClickOk: () => void;
}

const ConfirmApproveModal: React.FC<ConfirmApproveModalProps> = ({ isDisplayModal, accountStrings, handleCloseModal, handleClickOk }) => {
  const [confirmed, setConfirmed] = useState<Boolean>(false);
  const beforeImage = `${process.env.PUBLIC_URL}/images/confirmReportAuthTransition/confirmApproveBefore_${accountStrings.imageFileName}.png`;
  const afterImage = `${process.env.PUBLIC_URL}/images/confirmReportAuthTransition/confirmApproveAfter_${accountStrings.imageFileName}.png`;
  const contentsMargin = '30px';

  const clickCloseButton = () => {
    setConfirmed(false);
    handleCloseModal();
  };

  return (
    <Modal show={isDisplayModal} onHide={clickCloseButton} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>利用報告の{accountStrings.action}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: confirmed ? 'none' : 'block', textAlign: 'center' }}>
          <img src={beforeImage} alt="confirmApproveBefore" width="100%" style={{ padding: contentsMargin }} />
          <div style={{ paddingBottom: contentsMargin }}>
            選択した利用報告を
            <a style={{ color: 'green' }}>
              <b>{accountStrings.action}</b>
            </a>
            して、
            <br />
            {accountStrings.bossAccount}に送信しますか？
            <br />（{accountStrings.userAccount}からは編集等が行えなくなります）
            <br />
          </div>
        </div>
        <div style={{ display: !confirmed ? 'none' : 'block', textAlign: 'center' }}>
          <img src={afterImage} alt="confirmApproveAfter" width="100%" style={{ padding: contentsMargin }} />
          <div style={{ paddingBottom: contentsMargin }}>
            {accountStrings.bossAccount}への送信が
            <a style={{ color: 'green' }}>完了しました。</a>
            <br />
            利用報告にご協力いただきありがとうございます。
            <br />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: confirmed ? 'none' : 'block', textAlign: 'right', marginTop: '10px' }}>
        <Button variant="outline-primary" onClick={clickCloseButton}>
          キャンセル
        </Button>
        <Button
          variant="success"
          onClick={() => {
            setConfirmed(true);
            handleClickOk();
          }}
        >
          {accountStrings.action}する
        </Button>
      </Modal.Footer>
      <Modal.Footer style={{ display: !confirmed ? 'none' : 'block', textAlign: 'right' }}>
        <Button variant="secondary" onClick={clickCloseButton}>
          この画面を閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmApproveModal;
