import { Button, Modal } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState, useEffect } from 'react';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  itemList: any[];
  nextStep: (item: any) => void;
}

interface RowProps {
  syohin: any;
  selectListItem: (item: any, index: string) => void;
  selectIndex: string;
}

const cellStyle = { borderRight: 1, borderColor: 'grey.500' };

const Row: React.FC<RowProps> = ({ syohin, selectListItem, selectIndex }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell sx={cellStyle}>
          <IconButton size="small">{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={cellStyle}>
          {syohin.syohinTitle}
        </TableCell>
        <TableCell sx={cellStyle}>{syohin.hinban}</TableCell>
        <TableCell sx={cellStyle}>{syohin.videoType}</TableCell>
        <TableCell sx={cellStyle}>{syohin.hatsubaibi}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 9 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Box sx={{ fontWeight: 'bold' }}>映像タイトル一覧</Box>
              <Table>
                <TableBody>
                  {syohin.tracks.map((track, index) => (
                    <TableRow
                      key={`${syohin.hinban}-${index}`}
                      onClick={() => {
                        selectListItem({ ...syohin, ...track }, `${syohin.hinban}-${index}`);
                      }}
                      className={selectIndex === `${syohin.hinban}-${index}` ? 'alert-primary' : ''}
                    >
                      <TableCell sx={{ paddingLeft: 0 }}>{track.trackTitle}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SelectModal: React.FC<ModalProps> = ({ show, setShow, itemList, nextStep }) => {
  const [selectItem, setSelectItem] = useState<any>({});
  const [selectIndex, setSelectIndex] = useState<string>('');

  const selectListItem = (item: any, index: string) => {
    setSelectIndex(index);
    setSelectItem(item);
  };

  useEffect(() => {
    if (!show) return;
    setSelectIndex('');
    setSelectItem({});
  }, [show]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        setShow(false);
      }}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>映像作品を選択</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        パッケージタイトルをクリックして、表示される映像タイトルを選択してください。
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow key="syohin-header">
                <TableCell sx={cellStyle} />
                <TableCell sx={{ ...cellStyle, fontWeight: 'bold' }}>パッケージタイトル</TableCell>
                <TableCell sx={{ ...cellStyle, fontWeight: 'bold' }}>品番</TableCell>
                <TableCell sx={{ ...cellStyle, fontWeight: 'bold' }}>媒体種別</TableCell>
                <TableCell sx={{ ...cellStyle, fontWeight: 'bold' }}>発売日</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemList.map((item) => (
                <Row syohin={item} selectListItem={selectListItem} selectIndex={selectIndex} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          閉じる
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            nextStep(selectItem);
          }}
          disabled={!Object.keys(selectItem).length}
        >
          選択
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectModal;
