import { useEffect, useState } from 'react';
import { Row, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../utils';
import { KUBN1, WIDGET_TYPE } from './musicWidgetUtils';
import MusicKyokuSearchBox from './MusicKyokuSearchBox';
import MusicKyokuSelectList from './MusicKyokuSelectList';
import musicWidgetUtils from './musicWidgetUtils';

interface ModalProps {
  display: boolean;
  setDisplay: (value: boolean) => void;
  kubn: KUBN1;
  updateMediaMusic: (value: any) => void;
}

const SearchMusicByKyokuModal: React.FC<ModalProps> = ({ display, setDisplay, kubn, updateMediaMusic }) => {
  const widgetType = WIDGET_TYPE.KYOKU;
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [selectedFirstList, setSelectedFirstList] = useState<any>({});
  const [selectedSecondList, setSelectedSecondList] = useState<any>({});
  const [unknownChecked, setUnknownChecked] = useState<boolean>(false);
  const listColumns = musicWidgetUtils.getListColumns(widgetType, kubn);

  const onClickConfirm = () => {
    if (Object.keys(selectedSecondList).length === 0 && !unknownChecked) {
      toast.error(
        'アルバム・シングル等を選択してください。分からない場合は「不明（上記商品名、アルバム等のいずれか）」をチェックしてください。',
        toastConfig()
      );
      return;
    }
    if (unknownChecked) {
      // 収録楽曲までの情報を反映
      updateMediaMusic(selectedFirstList);
    } else {
      const fixedRecordParam = { ...selectedSecondList, syohinno: selectedSecondList.discno };
      delete fixedRecordParam.discno;
      updateMediaMusic(fixedRecordParam);
    }
    toast.success('選択した楽曲情報を入力項目に反映させました。', toastConfig());
    setDisplay(false);
    setSearchResult([]);
  };

  const expandSelectedMusicJson = (selectedMusic: any) => {
    return selectedMusic.syohin_info.map((syohinParams: any) => {
      const expand = { ...selectedMusic, ...syohinParams };
      delete expand.syohin_info;
      return expand;
    });
  };

  useEffect(() => {
    setSelectedFirstList({});
    setSelectedSecondList({});
    setUnknownChecked(false);
  }, [searchResult]);

  useEffect(() => {
    setSelectedSecondList({});
    setUnknownChecked(false);
  }, [selectedFirstList]);

  return (
    <Modal
      size="lg"
      show={display}
      onHide={() => {
        setDisplay(false);
        setSearchResult([]);
      }}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>利用楽曲からアルバム・シングル等を検索</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ padding: '10px' }}>
          <div>いずれかの項目を入力して、利用楽曲とアルバム・シングル等を検索してください。</div>
        </Row>
        <Row>
          <MusicKyokuSearchBox kubn={kubn} setSearchResult={setSearchResult} />
        </Row>
        <Row>
          <MusicKyokuSelectList listIndex={0} columns={listColumns[0]} displayItems={searchResult} setSelectedItem={setSelectedFirstList} />
        </Row>
        <Row>
          <MusicKyokuSelectList
            listIndex={1}
            columns={listColumns[1]}
            displayItems={'syohin_info' in selectedFirstList ? expandSelectedMusicJson(selectedFirstList) : []}
            setSelectedItem={setSelectedSecondList}
            unknownChecked={unknownChecked}
            setUnknownChecked={setUnknownChecked}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setDisplay(false);
            setSearchResult([]);
          }}
        >
          閉じる
        </Button>
        <Button variant="success" onClick={onClickConfirm}>
          反映
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchMusicByKyokuModal;
