import { Row, Col } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import VerticalScrollListComponent from '../../VerticalScrollListComponent';

interface Props {
  columns: TableColumn<any>[];
  displayItems: any[];
  setSelectedItem: (value: any) => void;
}

const MusicSakuhinSelectList: React.FC<Props> = ({ columns, displayItems, setSelectedItem }) => {
  return (
    <Row style={{ margin: '10px', marginTop: '20px' }}>
      <Col md={1}>
        <div style={{ alignItems: 'center', display: 'flex', flexFlow: 'column' }}>
          <img src={`${process.env.PUBLIC_URL}/images/musicWidget/onpu.png`} className="w-100 mt-1" />
        </div>
      </Col>
      <Col>
        <VerticalScrollListComponent columns={columns} data={displayItems} setSelectedItem={setSelectedItem} />
      </Col>
    </Row>
  );
};

export default MusicSakuhinSelectList;
