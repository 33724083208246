import DataTable, { TableColumn } from 'react-data-table-component';
import SelectCopyFromModal from '../modal/SelectCopyFromModal';
import { useState, useEffect, useMemo, Ref } from 'react';
import Export from '../CreateExportData';
import reportApi from '../../api/CallCreateReportApi';
import { toast } from 'react-toastify';
import { toastConfig, getMediaTitle } from '../../utils';
import { Report } from '../../interface/Report';
import RowButtonsComponent from './RowButtonsComponent';
import '../../../common.css';

interface TableProps {
  reports: Report[];
  selectRow: ({ selectedRows }: { selectedRows: any }) => void;
  isClearSelectedRow: boolean;
  moveConfirmPage: (record: any) => void;
  moveEditPage: (record: any) => void;
  moveCopyLessonPage: (record: any) => void;
  moveCopyLessonAndMediaPage: (record: any) => void;
  isDisplayEdit: boolean;
  isDisplayCopy: boolean;
  hiddenDLButtonRef: Ref<HTMLButtonElement>;
  selectableRowsVisibleOnly: boolean;
}

const Table: React.FC<TableProps> = ({
  reports,
  selectRow,
  isClearSelectedRow,
  moveConfirmPage,
  moveEditPage,
  moveCopyLessonPage,
  moveCopyLessonAndMediaPage,
  isDisplayEdit,
  isDisplayCopy,
  hiddenDLButtonRef,
  selectableRowsVisibleOnly,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const showCopyFromModal = (record: any) => {
    setShowModal(true);
    setSelectedRow(record);
  };
  const selectCopyFrom = async (from: string) => {
    setShowModal(false);
    if (from === 'class') {
      moveCopyLessonPage(selectedRow);
    } else {
      if (!(await reportApi.getSubCategory(selectedRow.media.categories))) {
        toast.error('コピーできない利用報告です。\n新規登録よりご報告ください。', toastConfig());
        return;
      }
      moveCopyLessonAndMediaPage(selectedRow);
    }
  };

  const getDisplayText = (text: string) => {
    if (!text) return '';
    if (text.length > 15) return `${text.slice(0, 15)}...`;
    return text;
  };

  const baseColumns: Array<TableColumn<any>> = [
    {
      name: 'ユーザー名',
      reorder: false,
      selector: (row) => row.user.name,
      sortable: true,
      wrap: true,
      format: (row) => getDisplayText(row.user.name),
    },
    {
      name: '授業情報',
      reorder: false,
      maxWidth: '10rem',
      sortable: true,
      wrap: true,
      format: (row) => getDisplayText(row.classInfo.subject + ' ' + String(row.classInfo.grade) + '学年' + ' ' + row.classInfo.personCount + '人'),
      selector: (row) => row.classInfo.subject + ' ' + String(row.classInfo.grade) + '学年' + ' ' + row.classInfo.personCount + '人',
    },
    {
      name: '入手・掲載元種別',
      reorder: false,
      selector: (row) => row.media.categories[0],
      minWidth: '130px',
      sortable: true,
      wrap: true,
      format: (row) => getDisplayText(row.media.categories[0]),
    },
    {
      name: '入手・掲載元の名称',
      reorder: false,
      minWidth: '130px',
      sortable: true,
      wrap: true,
      format: (row) => getDisplayText(getMediaTitle(row)),
      selector: (row) => getDisplayText(getMediaTitle(row)),
    },
    {
      name: '著作物の分類',
      reorder: false,
      maxWidth: '12rem',
      selector: (row) => row.work.subCategory.slice(2),
      format: (row) => getDisplayText(row.work.subCategory.slice(2)),
      sortable: true,
      wrap: true,
    },
    {
      name: '差戻理由',
      reorder: false,
      maxWidth: '12rem',
      sortable: true,
      selector: (row) => (row.comment ? row.comment.content : ''),
      wrap: true,
      format: (row) => getDisplayText(row.comment ? row.comment.content : ''),
    },
    {
      name: '',
      sortable: false,
      reorder: false,
      minWidth: '150px',
      cell: (record) => {
        return (
          <RowButtonsComponent
            isDisplayConfirmButton={true}
            isDisplayEditButton={isDisplayEdit}
            isDisplayCopyButton={isDisplayCopy}
            handleClickConfirmButton={() => moveConfirmPage(record)}
            handleClickEditButton={() => moveEditPage(record)}
            handleClickCopyButton={() => showCopyFromModal(record)}
          />
        );
      },
    },
    {
      name: 'シリアルコード',
      reorder: false,
      minWidth: '180px',
      selector: (row) => row._id,
      style: { color: 'gray' },
      sortable: true,
      wrap: true,
    },
  ];
  const [columns, setColumns] = useState<Array<TableColumn<any>>>(baseColumns);
  const downloadXlsxData = useMemo(() => <Export data={reports} hiddenDLButtonRef={hiddenDLButtonRef} />, [reports]);

  useEffect(() => {
    setColumns(baseColumns);
  }, [isDisplayEdit]);

  return (
    <>
      <DataTable
        className="mt-1"
        columns={columns}
        data={reports}
        selectableRows={true}
        onSelectedRowsChange={selectRow}
        pagination
        paginationResetDefaultPage={false}
        clearSelectedRows={isClearSelectedRow}
        noDataComponent="条件に一致する利用報告はありません。"
        responsive={true}
        selectableRowsVisibleOnly={selectableRowsVisibleOnly}
        actions={downloadXlsxData}
        noContextMenu
      />
      <SelectCopyFromModal show={showModal} setShow={setShowModal} nextStep={selectCopyFrom} />
    </>
  );
};
export default Table;
