import { WidgetProps } from '@rjsf/utils';
import { KUBN1 } from './common/musicWidgetUtils';
import OpenSearchMusicModalButton from './common/OpenSearchMusicModalButton';

// 市販配信音楽の商品検索と収録曲検索のウィジェット
function InputMusicHaishinWidget(props: WidgetProps) {
  return (
    <OpenSearchMusicModalButton
      label={[
        '市販配信音楽のアルバム・シングル情報、および利用楽曲情報を入力してください。',
        '以下のボタンから検索すると、該当する情報が自動反映されます。',
      ]}
      kubn={KUBN1.HAISHIN}
      onlySyohin={false}
      updateMediaMusic={props.formContext.updateMediaMusic}
    />
  );
}

export default InputMusicHaishinWidget;
