import { expectedExceptionClient } from './AxiosClientProvider';
import { KUBN1, KUBN2 } from '../components/musicWidget/common/musicWidgetUtils';

const MUSIC_API = '/v1/music/';

const getSyohin = async (discno: string, title: string, artist: string, kubn: KUBN1): Promise<any> => {
  try {
    const result = await expectedExceptionClient.get(MUSIC_API + 'Syohin', { params: { discno, title, artist, kubn } });
    return result.data;
  } catch (e) {
    throw e;
  }
};

const getKyoku = async (kyoku: string, artist: string, kubn1: KUBN1, kubn2: KUBN2): Promise<any> => {
  try {
    const result = await expectedExceptionClient.get(MUSIC_API + 'Kyoku', { params: { kyoku, artist, kubn1, kubn2 } });
    return result.data;
  } catch (e) {
    throw e;
  }
};

const getSakuhin = async (kyoku: string, artist: string): Promise<any> => {
  try {
    const result = await expectedExceptionClient.get(MUSIC_API + 'Sakunm', { params: { kyoku, artist } });
    return result.data;
  } catch (e) {
    throw e;
  }
};

const exportedObject = {
  getSyohin,
  getKyoku,
  getSakuhin,
};

export default exportedObject;
