import { Form } from 'react-bootstrap';

interface Props {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
}

const CustomTextBox: React.FC<Props> = ({ label, value, onChange, style }) => {
  return (
    <Form.Group style={style}>
      <Form.Label column style={{ marginLeft: '-15px' }}>
        {label}
      </Form.Label>
      <Form.Control type="text" value={value} onChange={onChange} />
    </Form.Group>
  );
};

export default CustomTextBox;
