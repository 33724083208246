import { TableColumn } from 'react-data-table-component';

export const KUBN1 = {
  CD: '0',
  HAISHIN: '1',
} as const;
export type KUBN1 = (typeof KUBN1)[keyof typeof KUBN1];

export const KUBN2 = {
  NAYOSE_ISRC: '0',
  NAYOSE_JASRAC: '1',
  NAYOSE_NexTone: '2',
} as const;
export type KUBN2 = (typeof KUBN2)[keyof typeof KUBN2];

export const WIDGET_TYPE = {
  SYOHIN: 'Syohin',
  KYOKU: 'Kyoku',
  SAKUHIN: 'Sakuhin',
} as const;
export type WIDGET_TYPE = (typeof WIDGET_TYPE)[keyof typeof WIDGET_TYPE];

const getListColumns = (widgetType: WIDGET_TYPE, kubn?: KUBN1): TableColumn<any>[][] => {
  switch (widgetType) {
    case WIDGET_TYPE.SYOHIN:
      switch (kubn) {
        case KUBN1.CD:
          return [
            [
              {
                name: 'CD商品名（アルバム等）',
                sortable: false,
                wrap: true,
                maxWidth: '60%',
                selector: (row: any) => row.title,
              },
              {
                name: '発売日',
                sortable: false,
                wrap: true,
                maxWidth: '20%',
                selector: (row: any) => {
                  const str = row.hatubaibi;
                  return str.slice(0, 4) + '/' + str.slice(4, 6) + '/' + str.slice(6, 8);
                },
              },
              {
                name: 'レコード商品番号',
                sortable: false,
                wrap: true,
                maxWidth: '20%',
                selector: (row: any) => row.syohinno,
              },
            ],
            [
              {
                name: 'No.',
                sortable: false,
                wrap: true,
                maxWidth: '8%',
                minWidth: '8%',
                selector: (row: any) => row.discno_trackno,
              },
              {
                name: '収録曲名',
                sortable: false,
                wrap: true,
                maxWidth: '33%',
                selector: (row: any) => row.kyoku,
              },
              {
                name: 'アーティスト',
                sortable: false,
                wrap: true,
                maxWidth: '30%',
                selector: (row: any) => row.artist,
              },
              {
                name: '作詞／作曲／編曲',
                sortable: false,
                wrap: true,
                maxWidth: '30%',
                selector: (row: any) => row.sakka,
              },
            ],
          ];
        case KUBN1.HAISHIN:
          return [
            [
              {
                name: '配信商品名（アルバム等）',
                sortable: false,
                wrap: true,
                maxWidth: '80%',
                selector: (row) => row.title,
              },
              {
                name: '発売日',
                sortable: false,
                wrap: true,
                maxWidth: '20%',
                selector: (row) => {
                  const str = row.hatubaibi;
                  return str.slice(0, 4) + '/' + str.slice(4, 6) + '/' + str.slice(6, 8);
                },
              },
            ],
            [
              {
                name: 'No.',
                sortable: false,
                wrap: true,
                maxWidth: '8%',
                minWidth: '8%',
                selector: (row: any) => row.discno_trackno,
              },
              {
                name: '収録楽曲名',
                sortable: false,
                wrap: true,
                maxWidth: '33%',
                selector: (row: any) => row.kyoku,
              },
              {
                name: 'アーティスト',
                sortable: false,
                wrap: true,
                maxWidth: '30%',
                selector: (row: any) => row.artist,
              },
              {
                name: '作詞／作曲／編曲',
                sortable: false,
                wrap: true,
                maxWidth: '30%',
                selector: (row: any) => row.sakka,
              },
            ],
          ];
        default:
          return [[]];
      }
    case WIDGET_TYPE.KYOKU:
      switch (kubn) {
        case KUBN1.CD:
          return [
            [
              {
                name: '収録曲名',
                sortable: false,
                wrap: true,
                maxWidth: '30%',
                selector: (row: any) => row.kyoku,
              },
              {
                name: 'アーティスト',
                sortable: false,
                wrap: true,
                maxWidth: '30%',
                selector: (row: any) => row.artist,
              },
              {
                name: '作詞／作曲',
                sortable: false,
                wrap: true,
                maxWidth: '20%',
                selector: (row: any) => row.sakka,
              },
              {
                name: 'ISRCコード',
                sortable: false,
                wrap: true,
                maxWidth: '20%',
                selector: (row: any) => row.isrc,
              },
            ],
            [
              {
                name: '収録曲名',
                sortable: false,
                wrap: true,
                maxWidth: '30%',
                selector: (row: any) => row.kyoku,
              },
              {
                name: '商品名（アルバム等）',
                sortable: false,
                wrap: true,
                maxWidth: '30%',
                selector: (row: any) => row.title,
              },
              {
                name: '発売日',
                sortable: false,
                wrap: true,
                maxWidth: '20%',
                selector: (row: any) => {
                  const str = row.hatubaibi;
                  return str.slice(0, 4) + '/' + str.slice(4, 6) + '/' + str.slice(6, 8);
                },
              },
              {
                name: 'レコード商品番号',
                sortable: false,
                wrap: true,
                maxWidth: '20%',
                selector: (row: any) => row.discno,
              },
            ],
          ];
        case KUBN1.HAISHIN:
          return [
            [
              {
                name: '配信曲名',
                sortable: false,
                wrap: true,
                maxWidth: '30%',
                selector: (row: any) => row.kyoku,
              },
              {
                name: 'アーティスト',
                sortable: false,
                wrap: true,
                maxWidth: '30%',
                selector: (row: any) => row.artist,
              },
              {
                name: '作詞／作曲',
                sortable: false,
                wrap: true,
                maxWidth: '20%',
                selector: (row: any) => row.sakka,
              },
              {
                name: 'ISRCコード',
                sortable: false,
                wrap: true,
                maxWidth: '20%',
                selector: (row: any) => row.isrc,
              },
            ],
            [
              {
                name: '配信曲名',
                sortable: false,
                wrap: true,
                maxWidth: '40%',
                selector: (row: any) => row.kyoku,
              },
              {
                name: '配信商品名（アルバム等）',
                sortable: false,
                wrap: true,
                maxWidth: '40%',
                selector: (row: any) => row.title,
              },
              {
                name: '発売日',
                sortable: false,
                wrap: true,
                maxWidth: '20%',
                selector: (row: any) => {
                  const str = row.hatubaibi;
                  return str.slice(0, 4) + '/' + str.slice(4, 6) + '/' + str.slice(6, 8);
                },
              },
            ],
          ];
        default:
          return [[]];
      }
    case WIDGET_TYPE.SAKUHIN:
      return [
        [
          {
            name: '楽曲名',
            sortable: false,
            wrap: true,
            maxWidth: '35%',
            selector: (row: any) => row.sakuhin,
          },
          {
            name: '作詞／作曲',
            sortable: false,
            wrap: true,
            maxWidth: '35%',
            selector: (row: any) => row.sakka,
          },
          {
            name: '検索ワード',
            sortable: false,
            wrap: true,
            maxWidth: '30%',
            selector: (row: any) => row.keyword,
          },
        ],
      ];
  }
};

const exportedObject = {
  getListColumns,
};

export default exportedObject;
