import { useEffect, useState } from 'react';
import { Row, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../utils';
import { WIDGET_TYPE } from './musicWidgetUtils';
import MusicSakuhinSearchBox from './MusicSakuhinSearchBox';
import MusicSakuhinSelectList from './MusicSakuhinSelectList';
import musicWidgetUtils from './musicWidgetUtils';

interface ModalProps {
  display: boolean;
  setDisplay: (value: boolean) => void;
  isMovie: boolean;
  updateMediaMusic: (value: any) => void;
}

const SearchMusicBySakuhinModal: React.FC<ModalProps> = ({ display, setDisplay, isMovie, updateMediaMusic }) => {
  const widgetType = WIDGET_TYPE.SAKUHIN;
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [selectedFirstList, setSelectedFirstList] = useState<any>({});
  const listColumns = musicWidgetUtils.getListColumns(widgetType);

  const onClickConfirm = () => {
    if (!Object.keys(selectedFirstList).length) {
      const msg = isMovie ? '動画に含まれる楽曲を選択してください。' : '利用楽曲を選択してください。';
      toast.error(msg, toastConfig());
      return;
    }
    const result = { ...selectedFirstList, kyoku: selectedFirstList.sakuhin };
    delete result.sakuhin;
    updateMediaMusic(result);
    toast.success('選択した楽曲情報を入力項目に反映させました。', toastConfig());
    setDisplay(false);
    setSearchResult([]);
  };

  useEffect(() => {
    setSelectedFirstList({});
  }, [searchResult]);

  return (
    <Modal
      size="lg"
      show={display}
      onHide={() => {
        setDisplay(false);
        setSearchResult([]);
      }}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>作品検索</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ padding: '10px' }}>
          {isMovie ? (
            <div>いずれかの項目を入力して、動画に含まれる楽曲を検索してください。</div>
          ) : (
            <div>いずれかの項目を入力して、利用した楽曲を検索してください。</div>
          )}
        </Row>
        <Row>
          <MusicSakuhinSearchBox setSearchResult={setSearchResult} />
        </Row>
        <Row>
          <MusicSakuhinSelectList columns={listColumns[0]} displayItems={searchResult} setSelectedItem={setSelectedFirstList} />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setDisplay(false);
            setSearchResult([]);
          }}
        >
          閉じる
        </Button>
        <Button variant="success" onClick={onClickConfirm}>
          反映
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchMusicBySakuhinModal;
