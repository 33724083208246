import { Form } from 'react-bootstrap';
import { createRef, useEffect, useState, useCallback } from 'react';
import InputForm from './InputWorkForm';
import { createTitleDict } from '../utils';
import reportApi from '../api/CallCreateReportApi';
import { createUiSchema } from '../utils';
import { getWorkHelpComponent } from '../helpUtils';
import React from 'react';
import { FrontWorkTmp, isFrontWorkTmpType } from '../interface/FrontWorkTmp';

interface WorkProp {
  smallCategoryArray: string[];
  subCategories: string[];
  toggleNextButtonClicked: boolean;
  toggleHalfWrittenButtonClicked: boolean;
  toggleUpdateForm: boolean;
  workData: FrontWorkTmp | {};
  setFormData: (formData: any, index: string, type: string) => void;
  setIsWorkDisabled: (index: number, disabled: boolean) => void;
  setWorkInfo: (itemNum: number, uiSchema: any, schema: any, subCategory: string, titleDict: object) => void;
  setDisplayHelpData: (data: any[], index: number) => void;
  index: number;
  helpData: any[][];
  setIsLoadingWork: (index: number, isLoading: boolean) => void;
}

const isEqual = (prevProps: any, nextProps: any) => {
  // 独自の比較処理
  const prevHelp = JSON.stringify(Object.entries(prevProps.helpData).sort());
  const nextHelp = JSON.stringify(Object.entries(nextProps.helpData).sort());

  // ヘルプデータの比較
  if (prevHelp !== nextHelp) return false;

  // 次へボタン押下と書きかけ保存ボタン押下時
  if (
    prevProps.toggleNextButtonClicked !== nextProps.toggleNextButtonClicked ||
    prevProps.toggleHalfWrittenButtonClicked !== nextProps.toggleHalfWrittenButtonClicked
  )
    return false;

  if (nextProps.workData.formData && prevProps.workData.formData) {
    // 入力値が存在する場合は比較
    const prev = JSON.stringify(Object.entries(prevProps.workData.formData).sort());
    const next = JSON.stringify(Object.entries(nextProps.workData.formData).sort());
    return prev === next;
  }

  // formDataが異なる場合はレンダリング
  return nextProps.workData.formData === prevProps.workData.formData;
};

const InputWorkArea = React.memo((props: WorkProp) => {
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [schema, setSchema] = useState<any>();
  const [uiSchema, setUiSchema] = useState<any>({});
  const [isRequiredValidation, setIsRequiredValidation] = useState<boolean>(false);
  const [toggleNextAction, setToggleNextAction] = useState<boolean>(false);
  const inputFormRef = createRef<HTMLButtonElement>();
  const [subCategory, setSubCategory] = useState<string>('');

  useEffect(() => {
    // setIsValidationOffを待つための遅延処理
    inputFormRef.current?.click();
  }, [toggleNextAction]);

  useEffect(() => {
    setIsRequiredValidation(true);
    setToggleNextAction((prev) => !prev);
    props.setIsLoadingWork(props.index, false);
  }, [props.toggleNextButtonClicked]);

  useEffect(() => {
    setIsRequiredValidation(false);
    setToggleNextAction((prev) => !prev);
    props.setIsLoadingWork(props.index, false);
  }, [props.toggleHalfWrittenButtonClicked]);

  // 初回と著作物削除時にフォームを更新する
  useEffect(() => {
    (async () => {
      props.setIsLoadingWork(props.index, true);
      if (Object.keys(props.workData).length && isFrontWorkTmpType(props.workData)) {
        // 既にデータが存在する場合
        setSubCategory(props.workData.subCategory);
        await updateDisplaySchema(props.workData.subCategory);
        setFormData(props.workData.formData);
      } else {
        // データが存在しない場合
        if (props.smallCategoryArray[0] === '新聞' || props.subCategories.length === 1) {
          // 特殊なケースは事前に分類に対する各種情報をセットする必要がる
          setSubCategory(props.subCategories[0]);
          await updateDisplaySchema(props.subCategories[0]);
        } else {
          // 通常は空をセットすればよい
          setSchema({});
        }
        setFormData({});
      }
      props.setIsLoadingWork(props.index, false);
    })();
  }, [props.toggleUpdateForm]);

  // スキーマの変更に応じてuiSchema/workDisabled/displayForm/displayHelpDataを更新
  useEffect(() => {
    // useStateの初期化時はスキップ
    if (schema === undefined) return;
    (async () => {
      const existSchema = Object.keys(schema).length !== 0;
      props.setIsWorkDisabled(props.index, !existSchema);
      setDisplayForm(existSchema);
      const uiSchema = createUiSchema(schema);
      setUiSchema(uiSchema);

      // ↓　ヘルプを取得する処理
      if (existSchema) {
        const subCategoryDict = { subcategoryDescription: subCategory.slice(2, subCategory.length) };
        const titleDict = createTitleDict(schema);
        const subCategoryAndTitleDict = { ...subCategoryDict, ...titleDict };
        Promise.allSettled(
          Object.keys(subCategoryAndTitleDict).map((key) => getWorkHelpComponent(props.smallCategoryArray[0], subCategoryAndTitleDict[key]))
        )
          .then((results: PromiseSettledResult<any>[]) => {
            const success: any[] = results.filter((result) => result.status === 'fulfilled');
            const Components = success.map((item) => item.value);
            props.setDisplayHelpData(Components, props.index);
          })
          .catch(() => {
            props.setDisplayHelpData([], props.index);
          });
        props.setWorkInfo(props.index, uiSchema, schema, subCategory, titleDict);
      } else {
        const helpComponent = await getWorkHelpComponent(props.smallCategoryArray[0], '分類未選択');
        props.setDisplayHelpData([helpComponent], props.index);
      }
    })();
  }, [schema]);

  const updateDisplaySchema = async (selectedSubCategory: string) => {
    if (selectedSubCategory) {
      // 選択済みまたは選択された著作物の分類に応じてスキーマをセット
      const schema = await reportApi.getWorkSchema(props.smallCategoryArray, selectedSubCategory);
      setSchema(schema);
      setFormData({});
    } else {
      // 著作物の分類が未選択にされた場合
      setSchema({});
      setFormData({});
    }
  };

  const changeFormData = useCallback(
    (formData: any, type: string) => {
      if (type === 'change') setFormData(formData);
      props.setFormData(formData, String(props.index), type);
    },
    [props.index]
  );

  return (
    <div>
      {props.subCategories.length !== 1 && (
        <Form.Control
          as="select"
          value={subCategory}
          onChange={async (event) => {
            const selectedSubCategory = event.currentTarget.value;
            setSubCategory(selectedSubCategory);
            await updateDisplaySchema(selectedSubCategory);
          }}
        >
          <option value="">▼著作物の分類を選択してください</option>
          {props.subCategories.map((item) => (
            <option key={item} value={item}>
              {' '}
              {item.slice(2, item.length)}
            </option>
          ))}
        </Form.Control>
      )}
      {displayForm && (
        <InputForm
          ref={inputFormRef}
          schema={schema}
          setFormData={changeFormData}
          uiSchema={uiSchema}
          isRequiredValidation={isRequiredValidation}
          formData={formData}
          idPrefix={String(props.index)}
        />
      )}
    </div>
  );
}, isEqual);

export default InputWorkArea;
