import { WidgetProps } from '@rjsf/utils';
import { KUBN1 } from './common/musicWidgetUtils';
import OpenSearchMusicModalButton from './common/OpenSearchMusicModalButton';

// 市販音楽CD等の商品検索のみのウィジェット
function InputMusicCdSyohinWidget(props: WidgetProps) {
  return (
    <OpenSearchMusicModalButton
      label={['市販音楽CD等のアルバム・シングル情報を入力してください。', '以下のボタンから検索すると、該当する情報が自動反映されます。']}
      kubn={KUBN1.CD}
      onlySyohin={true}
      updateMediaMusic={props.formContext.updateMediaMusic}
    />
  );
}

export default InputMusicCdSyohinWidget;
