import { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import GridTable from './GridTable';
import lodash from 'lodash';

interface Props {
  columns: TableColumn<any>[];
  data: any[];
  disabled?: boolean;
  setSelectedItem: (value: any) => void;
}

const VerticalScrollListComponent: React.FC<Props> = ({ columns, data, disabled = false, setSelectedItem }) => {
  const [selectedRow, setSelectedRow] = useState<any>({});
  const selectedRowStyles = [{ when: (row: any) => lodash.isEqual(row, selectedRow), style: { color: '#004085', backgroundColor: '#cce5ff' } }];

  useEffect(() => {
    if (data.length === 0) setSelectedRow({});
  }, [data]);

  return (
    <div style={{ height: '241px', borderBottom: 'solid 1px #E0E0E0', borderLeft: 'solid 1px #E0E0E0', borderRight: 'solid 1px #E0E0E0' }}>
      <GridTable
        columns={columns}
        data={data}
        selectableRows={false}
        onRowClicked={(row: any) => {
          setSelectedItem(row);
          setSelectedRow(row);
        }}
        conditionalRowStyles={selectedRowStyles}
        fontSize="14px"
        autoScroll={true}
        disabled={disabled}
        pagination={false}
        persistTableHead={true}
        fixedHeader={true}
        fixedHeaderScrollHeight="240px"
      />
    </div>
  );
};

export default VerticalScrollListComponent;
