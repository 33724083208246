import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import VerticalScrollListComponent from '../../VerticalScrollListComponent';

interface Props {
  listIndex: number;
  columns: TableColumn<any>[];
  displayItems: any[];
  onlySyohin: boolean;
  setSelectedItem: (value: any) => void;
}

const MusicSyohinSelectList: React.FC<Props> = ({ listIndex, columns, displayItems, onlySyohin, setSelectedItem }) => {
  const [displayItemsAddDiscAndTrackNo, setDisplayItemsAddDiscAndTrackNo] = useState<any[]>([]);

  useEffect(() => {
    if (listIndex === 1) {
      // 1つの商品に複数の円盤がある場合、discno-tracknoで表示するための項目を追加する
      const discNoCount = new Set(displayItems.map((item) => item.discno)).size;
      setDisplayItemsAddDiscAndTrackNo(
        displayItems.map((item) => {
          if (discNoCount > 1) return { ...item, discno_trackno: `${item.discno}-${item.trackno}` };
          return { ...item, discno_trackno: item.trackno };
        })
      );
    } else {
      setDisplayItemsAddDiscAndTrackNo(displayItems);
    }
  }, [displayItems]);

  return (
    <Row style={{ margin: '10px', marginTop: '20px' }}>
      <Col md={1}>
        <div style={{ alignItems: 'center', display: 'flex', flexFlow: 'column' }}>
          <img src={`${process.env.PUBLIC_URL}/images/musicWidget/${listIndex === 0 ? 'disc' : 'onpu'}.png`} className="w-100 mt-1" />
          {listIndex === 0 && !onlySyohin && <img src={`${process.env.PUBLIC_URL}/images/musicWidget/flow.png`} className="w-50 mt-2" />}
        </div>
      </Col>
      <Col>
        <VerticalScrollListComponent columns={columns} data={displayItemsAddDiscAndTrackNo} setSelectedItem={setSelectedItem} />
      </Col>
    </Row>
  );
};

export default MusicSyohinSelectList;
