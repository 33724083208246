import { Dispatch, SetStateAction } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import VerticalScrollListComponent from '../../VerticalScrollListComponent';

interface Props {
  listIndex: number;
  columns: TableColumn<any>[];
  displayItems: any[];
  setSelectedItem: (value: any) => void;
  unknownChecked?: boolean;
  setUnknownChecked?: Dispatch<SetStateAction<boolean>>;
}

const MusicKyokuSelectList: React.FC<Props> = ({
  listIndex,
  columns,
  displayItems,
  setSelectedItem,
  unknownChecked = false,
  setUnknownChecked = () => {},
}) => {
  return (
    <>
      <Row style={{ margin: '10px', marginTop: '20px' }}>
        <Col md={1}>
          <div style={{ alignItems: 'center', display: 'flex', flexFlow: 'column' }}>
            <img src={`${process.env.PUBLIC_URL}/images/musicWidget/${listIndex === 0 ? 'onpu' : 'disc'}.png`} className="w-100 mt-1" />
            {listIndex === 0 && <img src={`${process.env.PUBLIC_URL}/images/musicWidget/flow.png`} className="w-50 mt-2" />}
          </div>
        </Col>
        <Col>
          <VerticalScrollListComponent columns={columns} data={displayItems} disabled={unknownChecked} setSelectedItem={setSelectedItem} />
        </Col>
      </Row>
      {/* 利用楽曲選択リスト（2番目のリスト）の場合、不明のチェックボックスを表示 */}
      {listIndex === 1 && (
        <Row style={{ margin: '10px', marginTop: '-10px', width: '100%' }}>
          <Col md={{ span: 11, offset: 1 }}>
            <Form.Group controlId="unknownCheckbox">
              <Form.Check
                type="checkbox"
                label={<div style={{ fontSize: '14px', marginTop: '3px', marginLeft: '3px' }}>不明（上記商品名、アルバム等のいずれか）</div>}
                checked={unknownChecked}
                disabled={!displayItems.length}
                onChange={() => setUnknownChecked((pre) => !pre)}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MusicKyokuSelectList;
