import { WidgetProps } from '@rjsf/utils';
import { Button, Form, Col, Alert } from 'react-bootstrap';
import { useState } from 'react';
import api from '../api/CallListApi';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import Modal from './modal/SelectMovieModal';
import Axios from 'axios';
import { useErrorBoundary } from 'react-error-boundary';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';

function MovieWidget(props: WidgetProps) {
  const [keyword1, setKeyword1] = useState('');
  const [keyword2, setKeyword2] = useState('');
  const [keyword1Operator, setkeyword1Operator] = useState<'contains' | 'startsWith'>('contains');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [itemList, setItemList] = useState<any[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch();

  const search = async () => {
    try {
      dispatch(startLoading());
      const data = await api.getMovie(keyword1, keyword1Operator, keyword2);
      toast.success(data.length < 500 ? `${data.length}件該当しました。` : '500件以上該当しました。500件までを表示しています。', toastConfig());
      setErrorMsg('');
      setShowAlert(false);
      setItemList(data);
      setShowModal(true);
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400) {
        setErrorMsg(e.response.data);
        setShowAlert(true);
      } else showBoundary(e);
    } finally {
      dispatch(stopLoading());
    }
  };

  const selectToastStyles = {
    width: '400px',
    textAlign: 'center',
    whiteSpace: 'pre-line',
  };

  const setSelectedItem = (item: any) => {
    setShowModal(false);
    props.formContext.updateMovie(item);
    toast.success(`作品情報を反映しました。\n「サブタイトル、話数」と「再生した時間」\nの欄をご入力ください。`, {
      ...toastConfig(),
      autoClose: 8000,
      style: selectToastStyles,
    });
  };
  return (
    <div>
      <Form.Row>
        <Col md={12}>
          <Alert variant="danger" show={showAlert}>
            {errorMsg}
          </Alert>
        </Col>
        <Col md={12}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              キーワード1
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                placeholder="必須"
                onChange={(event) => {
                  setKeyword1(event.target.value);
                }}
              />
            </Col>
            <Col sm="3">
              <Form.Check
                inline
                label="を含む"
                style={{ fontSize: '14px', marginTop: '10px' }}
                name="keyword1Operator"
                value="contains"
                type="radio"
                id="contains"
                defaultChecked={true}
                onChange={(e: any) => setkeyword1Operator('contains')}
              />
              <Form.Check
                inline
                label="から始まる"
                style={{ fontSize: '14px', marginTop: '10px' }}
                name="keyword1Operator"
                value="startsWith"
                type="radio"
                id="startsWith"
                onChange={(e: any) => setkeyword1Operator('startsWith')}
              />
            </Col>
            <Col sm="1">
              <Button
                variant="primary"
                type="button"
                as="input"
                value="検索"
                onClick={() => {
                  search();
                }}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group as={Row} className="mb-3" controlId="keyword2">
            <Form.Label column sm="2">
              キーワード2
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                placeholder="任意"
                onChange={(event) => {
                  setKeyword2(event.target.value);
                }}
              />
            </Col>
            <Col sm="3">
              <Form.Check
                inline
                label="を含む"
                style={{ fontSize: '14px', marginTop: '10px' }}
                name="keyword2Operator"
                value="contains"
                type="radio"
                id="contains2"
                defaultChecked={true}
              />
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <p>※書籍等に付属の映像や学校専売品等は検索できないため、手入力してください。</p>
      <Modal show={showModal} setShow={setShowModal} itemList={itemList} nextStep={setSelectedItem} />
    </div>
  );
}

export default MovieWidget;
