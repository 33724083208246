import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  institutionId: string;
  updateDisplayFunc: () => void;
  markAsNoSubmissionAPI: (institutionId: string) => Promise<any>;
}

const ConfirmSubmissionModal: React.FC<ModalProps> = ({ show, setShow, institutionId, updateDisplayFunc, markAsNoSubmissionAPI }) => {
  const [confirmed, setConfirmed] = useState<Boolean>(false);
  const CONTENTS_MARGIN = '30px';

  const clickConfirmButton = async () => {
    await markAsNoSubmissionAPI(institutionId);
    setConfirmed(true);
    updateDisplayFunc();
  };

  const closeModal = () => {
    setConfirmed(false);
    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>利用なしの報告</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: confirmed ? 'none' : 'block', textAlign: 'center' }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/confirmReportAuthTransition/confirmSubmissionBefore.png`}
            alt="ConfirmSubmissionBefore"
            width="100%"
            style={{ padding: CONTENTS_MARGIN }}
          />
          <div style={{ paddingBottom: CONTENTS_MARGIN }}>
            SARTRASへ「利用なし」として<a style={{ color: 'red' }}>報告します。</a>
            <br />
            ※後から利用報告を提出した場合、この報告は自動的に取り消されます。
            <br />
          </div>
        </div>
        <div style={{ display: !confirmed ? 'none' : 'block', textAlign: 'center' }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/confirmReportAuthTransition/confirmSubmissionAfter.png`}
            alt="ConfirmSubmissionAfter"
            width="100%"
            style={{ padding: CONTENTS_MARGIN }}
          />
          <div style={{ paddingBottom: CONTENTS_MARGIN }}>
            SARTRASへの報告を<a style={{ color: 'red' }}>完了しました。</a>
            <br />
            利用報告にご協力いただきありがとうございます。
            <br />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: confirmed ? 'none' : 'block', textAlign: 'right' }}>
        <Button variant="secondary" onClick={() => closeModal()}>
          キャンセル
        </Button>
        <Button variant="danger" onClick={() => clickConfirmButton()}>
          報告する
        </Button>
      </Modal.Footer>
      <Modal.Footer style={{ display: !confirmed ? 'none' : 'block', textAlign: 'right' }}>
        <Button variant="secondary" onClick={() => closeModal()}>
          この画面を閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmSubmissionModal;
