import { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { KUBN1 } from './musicWidgetUtils';
import SearchMusicBySyohinModal from './SearchMusicBySyohinModal';
import SearchMusicByKyokuModal from './SearchMusicByKyokuModal';

interface Props {
  label: string[];
  kubn: KUBN1;
  onlySyohin: boolean;
  updateMediaMusic: (value: any) => void;
}

const OpenSearchMusicModalButton: React.FC<Props> = ({ label, kubn, onlySyohin, updateMediaMusic }) => {
  const [displayMusicBySyohinModal, setDisplayMusicBySyohinModal] = useState<boolean>(false);
  const [displayMusicByKyokuModal, setDisplayMusicByKyokuModal] = useState<boolean>(false);

  return (
    <div>
      {label.map((text, index) => (
        <div key={index}>
          {text}
          <br />
        </div>
      ))}
      <Row style={{ padding: '10px', marginTop: '20px' }}>
        <Col md={5}>
          <Button type="button" onClick={() => setDisplayMusicBySyohinModal(true)}>
            {onlySyohin ? (
              <>
                <img src={`${process.env.PUBLIC_URL}/images/musicWidget/discWhite.png`} className="p-1" style={{ height: '52px' }} />
                <div style={{ textAlign: 'left', marginLeft: '10px', marginRight: '10px' }}>アルバム・シングル等を検索</div>
              </>
            ) : (
              <>
                <img src={`${process.env.PUBLIC_URL}/images/musicWidget/searchMusicBySyohin.png`} className="w-50 p-1" />
                <div style={{ textAlign: 'left', marginLeft: '20px' }}>
                  アルバム・シングル等から
                  <br />
                  利用楽曲を検索
                </div>
              </>
            )}
          </Button>
        </Col>
        {!onlySyohin && (
          <Col md={5}>
            <Button type="button" onClick={() => setDisplayMusicByKyokuModal(true)}>
              <img src={`${process.env.PUBLIC_URL}/images/musicWidget/searchMusicByKyoku.png`} className="w-50 p-1" />
              <div style={{ textAlign: 'left', marginLeft: '40px' }}>
                利用楽曲から
                <br />
                アルバム・シングル等を検索
              </div>
            </Button>
          </Col>
        )}
      </Row>
      <SearchMusicBySyohinModal
        display={displayMusicBySyohinModal}
        setDisplay={setDisplayMusicBySyohinModal}
        kubn={kubn}
        onlySyohin={onlySyohin}
        updateMediaMusic={updateMediaMusic}
      />
      <SearchMusicByKyokuModal
        display={displayMusicByKyokuModal}
        setDisplay={setDisplayMusicByKyokuModal}
        kubn={kubn}
        updateMediaMusic={updateMediaMusic}
      />
    </div>
  );
};

export default OpenSearchMusicModalButton;
