import { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { WidgetProps } from '@rjsf/utils';
import SearchMusicBySakuhinModal from './common//SearchMusicBySakuhinModal';

// 動画用の作品検索のウィジェット
function InputMusicMovieSakuhinWidget(props: WidgetProps) {
  const [displayMusicSakuhinModal, setDisplayMusicSakuhinModal] = useState<boolean>(false);
  return (
    <div>
      <Row>
        <Col md={4}>
          <Button type="button" onClick={() => setDisplayMusicSakuhinModal(true)}>
            <div style={{ display: 'flex', padding: '5px' }}>
              <img src={`${process.env.PUBLIC_URL}/images/musicWidget/onpuWhite.png`} style={{ width: '20%', height: '20%', marginTop: '6px' }} />
              <div style={{ fontSize: '18px', marginTop: '6px' }}>
                　動画に含まれる
                <br />
                楽曲を検索
              </div>
            </div>
          </Button>
        </Col>
        <Col>
          <div style={{ textAlign: 'left', margin: '8px' }}>
            左のボタンから検索を行うと、
            <br />
            該当する情報が自動反映されます。
          </div>
        </Col>
      </Row>
      <SearchMusicBySakuhinModal
        display={displayMusicSakuhinModal}
        setDisplay={setDisplayMusicSakuhinModal}
        isMovie={true}
        updateMediaMusic={props.formContext.updateMediaMusic}
      />
    </div>
  );
}

export default InputMusicMovieSakuhinWidget;
