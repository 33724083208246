import { Button, Card } from 'react-bootstrap';
import { DANCE_TITLE } from '../utils';
interface Props {
  subBigMediaCategory: string;
  selectedItem: string;
  setSelected: (select: string, num: number) => void;
}

const SelectBigMediaCategory: React.FC<Props> = ({ selectedItem, setSelected, subBigMediaCategory }) => {
  const isActive = (value: string) => {
    return subBigMediaCategory !== '' && selectedItem === value;
  };

  const selectItem = (select: string, event: any) => {
    setSelected(select, event.pageY);
  };

  return (
    <>
      <Card body>
        <div>入手・掲載元の選択</div>
        <Button
          className="book-button-color highlighted-button publisher-button-size m-10"
          type="button"
          onClick={(event) => {
            selectItem('書籍・本', event);
          }}
          active={isActive('書籍・本')}
        >
          <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/book.png`} alt="book_icon" className="w-20" />
          書籍・本
        </Button>

        <Button
          className="book-button-color highlighted-button publisher-button-size m-10"
          type="button"
          onClick={(event) => {
            selectItem('雑誌', event);
          }}
          active={isActive('雑誌')}
        >
          <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/magazine.png`} alt="magazine_icon" className="w-20" />
          雑誌
        </Button>

        <Button
          className="textbook-button-color highlighted-button publisher-button-size m-10"
          type="button"
          onClick={(event) => {
            selectItem('検定教科書', event);
          }}
          active={isActive('検定教科書')}
        >
          <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/school.png`} alt="school_icon" className="w-20" />
          検定教科書
        </Button>

        <Button
          className="textbook-button-color highlighted-button publisher-button-size m-10"
          type="button"
          onClick={(event) => {
            selectItem('教師用指導書及びその付属教材、指導者用デジタル教科書', event);
          }}
          active={isActive('教師用指導書及びその付属教材、指導者用デジタル教科書')}
        >
          <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/school.png`} alt="school_icon" className="w-20" />
          教師用指導書及びその付属教材、指導者用デジタル教科書
        </Button>

        <Button
          className="internet-button-color highlighted-button publisher-button-size m-10"
          type="button"
          onClick={(event) => {
            selectItem('インターネット上の著作物等全般', event);
          }}
          active={isActive('インターネット上の著作物等全般')}
        >
          <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/internet.png`} alt="internet" className="w-20" />
          インターネット上の著作物等全般
        </Button>

        {subBigMediaCategory === DANCE_TITLE && (
          <>
            <Button
              className="music-button-color highlighted-button publisher-button-size m-10"
              type="button"
              onClick={(event) => {
                selectItem('映像DVD・ブルーレイ等', event);
              }}
              active={isActive('映像DVD・ブルーレイ等')}
            >
              <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/movie.png`} alt="movie_icon" className="w-20" />
              映像DVD・ブルーレイ等
            </Button>
            <Button
              className="broadcast-button-color highlighted-button publisher-button-size m-10"
              type="button"
              onClick={(event) => {
                selectItem('放送（テレビ・ラジオ）', event);
              }}
              active={isActive('放送（テレビ・ラジオ）')}
            >
              <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/tv.png`} alt="tv_icon" className="w-20" />
              放送（テレビ・ラジオ）
            </Button>
            <Button
              className="broadcast-button-color highlighted-button publisher-button-size m-10"
              type="button"
              onClick={(event) => {
                selectItem('有線放送（ケーブルテレビ・有線ラジオ）', event);
              }}
              active={isActive('有線放送（ケーブルテレビ・有線ラジオ）')}
            >
              <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/connection.png`} alt="connection_icon" className="w-20" />
              有線放送（ケーブルテレビ・有線ラジオ）
            </Button>
          </>
        )}
        <Button
          className="paper-button-color highlighted-button publisher-button-size m-10"
          type="button"
          onClick={(event) => {
            selectItem('冊子、パンフレット等', event);
          }}
          active={isActive('冊子、パンフレット等')}
        >
          <img src={`${process.env.PUBLIC_URL}/images/bigMediaCategory/pamphlet.png`} alt="pamphlet" className="w-20" />
          冊子、パンフレット等
        </Button>
      </Card>
    </>
  );
};

export default SelectBigMediaCategory;
